export const closeReportIframeGlobal = () => {
    const overlayElements = document.querySelectorAll('[id^="reportOverlay"]');
    const iframeElements = document.querySelectorAll('[id^="iframeReportContainer"]');
  
    overlayElements.forEach(element => {
      element.style.display = 'none';
    });
  
    iframeElements.forEach(element => {
      element.style.display = 'none';
    });

    document.documentElement.style.overflow = '';
  };
  