import React from "react";
import styles from "../featured/featured.module.scss";
import RechargedRatingImage from "../../../static/recharged_rating.png";
import ReportPopupSRP from "../extraData/reportPopupSRP";
import ReportPopupSRPRevamp from "../extraData/reportPopupSRPRevamp";
import "../extraData/extradata.module.scss";

function RenderRating(rating) {
  let convertedNumber = Number(rating);
  if (convertedNumber > 5) {
    convertedNumber /= 100;
  }

  const fullCircles = Math.floor(convertedNumber);
  const decimalPart = convertedNumber - fullCircles;
  const hasHalfCircle = decimalPart >= 0.5; // Adjusted condition for a half circle
  const hasEmptyCircle = decimalPart >= 0.5 || convertedNumber === 0; // Adjusted condition for an empty circle

  const circles = [];
  // Generate filled circles
  for (let i = 0; i < 5; i++) {
    let circleClass = styles.circle;

    if (i < fullCircles) {
      circleClass += " " + styles.filled;
    } else if (i === fullCircles && hasHalfCircle) {
      circleClass += " " + styles["half-filled"];
    } else if (i >= fullCircles + (hasHalfCircle ? 1 : 0)) {
      circleClass += " " + styles.empty;
    }

    circles.push(
      <div key={i} className={circleClass}>
        <img src={RechargedRatingImage} alt="Recharged Rating" />
      </div>
    );
  }

  return <div className={styles.rating}>{circles}</div>;
}

function RechargedRating(props) {
  const decimalScoreDisplay = props.rating
    ? (Number(props.rating) / 100).toFixed(2)
    : null;

  const handleScoreContainerClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const hasPendingScore = !props?.rating;

  return (
    <div
      className={`${styles.rechargedRating} ${
        hasPendingScore ? styles.pendingScore : ""
      }`}
    >
      {props?.rating && Number(props?.rating) > 0 ? (
        <div style={{ display: 'flex', gap: '16px', justifyContent: 'center'}}>
          <div className={props.recommended ? styles.RecommenededScoreContainerWrapper : styles.RechargedScoreContainerWrapper}>
            {RenderRating(props.rating)}
            <div className={styles.decimalScoreContainer}>
              {decimalScoreDisplay && (
                <h5 className={styles.decimalScore}>{decimalScoreDisplay}</h5>
              )}
            </div>
            
          </div>
          <div className={props.recommended ? styles.RecommenededScoreContainerWrapper : styles.RechargedScoreContainerWrapper}>
          <div
              className={styles.RechargedScoreContainer}
              onClick={handleScoreContainerClick}
              onTouchStart={handleScoreContainerClick}
            >
              <p>Recharged Score</p>
            </div>
            {props.rating && (
              <div className={!props.recommended && 'SRPReportContainer'}>
                <ReportPopupSRPRevamp vin={props.vin} featured={props.featured || false}/>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div
            className={`${styles.circle} ${styles.filled} ${styles.circFlex}`}
          >
            <img
              className={styles.pendingRatingImg}
              src={RechargedRatingImage}
              alt="Recharged Rating"
            />
          </div>
          <div className={styles.pendingRatingCont}>
            <p className={styles.pendingRating}>Recharged Score Pending</p>
          </div>
        </>
      )}
    </div>
  );
}

export default RechargedRating;
